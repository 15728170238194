import React, { useState, useEffect } from 'react'
import "./Rate.css"
const Rate = () => {



    
    const [formData, setFormData] = useState({
        email: "",
        name: "",
        subject: "",
        message: "",

    });
    

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    return (
        <div className="ratepage">
            <section className="contact" id="contact">

                <h1 className="heading"> add your  stats </h1>


                <div className="row">


                    <form onSubmit={(e) => {
                        e.preventDefault();
                        fetch("https://sheetdb.io/api/v1/7fiaagjx85whr", {
                            method: "post",
                            headers: {
                                Accept: "application/json, text/plain, */*",
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(formData),
                        })
                            .then((res) => res.json())
                            .then((res) => console.log(res));
                        if (window.confirm('Thank you for your submission!')) {
                            window.location.reload();

                        }
                        else {
                            alert("Thank you for your submission!")
                        }


                    }

                    }
                        method="POST"
                        id="form">
                        <p className="input_title"> College <span className="required">*</span></p> 
                        <input type="text" placeholder="e.g. Harvard" name="Name_of_Team" className="box" onChange={handleChange}
                            required />

{/* -------blank---------- */}
                        <p className="input_title"> College Acceptance <span className="required">*</span></p>

                        <select  name="results" className="box" required onChange={handleChange} >
                            <option></option>
                            <option value="Accepted">Accepted</option>
                            <option value="Rejected">Rejected</option>
                            <option value="Waitlisted">Waitlisted</option>
                        </select>



                        <p className="input_title"> Unweighted GPA <span className="required">*</span></p>
                        <input type="text" name="Final_score" className="box" placeholder="e.g. 3.92"  onChange={handleChange}
                            required />


                        <p className="input_title"> Weighted GPA</p>
                        <input type="text" id="First_quarter_score" name="First_quarter_score" placeholder="e.g. 4.25" className="box" onChange={handleChange}
                             />

                        <p className="input_title"> Class Rank</p>
                        <input type="text" id="Second_quarter_score" name="Second_quarter_score" placeholder="e.g. 12/210" className="box" onChange={handleChange}
                             />

                        <p className="input_title"> SAT</p>
                        <input type="text" id="Third_quarter_score" name="Third_quarter_score" placeholder="e.g. 1410" className="box" onChange={handleChange}
                             />

                        <p className="input_title"> ACT</p>
                        <input type="text" id="Fourth_quarter_score" name="Fourth_quarter_score" placeholder="e.g. 32" className="box" onChange={handleChange}
                             />

                        <p className="input_title"> Major </p>
                        <input type="text" name="MVP" placeholder="e.g. Chemical Engineering" className="box" onChange={handleChange}
                             />

                       
                        <p className="input_title"> Additional Information <span className="required">*</span></p>
                        <textarea name="Highlights" placeholder="AP classes, AP scores, extracurricular activities, volunteer experience, awards, etc." className="box" id="" cols="30" rows="10" onChange={handleChange}
                            required></textarea>

                       

                        <input type="submit" value="Submit" className="btn" />
                    </form>

                </div>

            </section>
        </div>
    )
}

export default Rate
