import React from 'react'
import "./About.css"
import about from "./about-img.PNG"
import { Link } from 'react-router-dom'
const About = () => {
    return (

        <section className="about" id="about">

            <h1 className="heading"> about us </h1>

            <div className="row">

                <div className="image">
                    <img src={about} alt="about" />
                </div>

                <div className="content">
                    <h3 className="title">Real students, real stats!</h3>
                    <p className="lowercase">Are you applying to college? Are you wondering what GPA, test scores, etc. you need to get into your dream school? Helping students with their college search is what we do! <br /> <br /> College Admission Stats easily allows students to find admission stats to any school they plan on applying to. Effortlessly find the stats that students were accepted with. Post your stats today to help future students!</p>
                    <Link to="/search" className="btn">Search now</Link>
                </div>

            </div>

        </section>

    )
}

export default About
